import React, { useEffect, useState } from "react";
import "../../style/adminstyle/Feedback.scss";
import AdminSidebar from "../../components/admin/AdminSidebar";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AdditionalFeedback = () => {
  const navigate = useNavigate();
  const [feedbacks, setFeedbacks] = useState([]);

  const fetchFeedbacks = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/feedback/get-feedbacks`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      console.log("Users fetched:", response.data); // Add logging here
      setFeedbacks(response.data);
    } catch (error) {
      if (error.response && error.response.status >= 400) {
        window.location.href = '/';
      } else {
        console.error('Failed to fetch users', error); // Add logging here
      }
    }
  }
  const handleDeleteFeedback = async (questionId) => {
   
  };

  useEffect(() => {
    fetchFeedbacks();
  }, []);


  return (
    <div className="admin-container">
      <AdminSidebar />
      <div className="feedback-container">
        <div className="header">
          <h2>Feedback</h2>
          <p className="text-muted">Review feedback from your users.</p>
        </div>
        <div className="feedback-table-wrapper">
          <table className="feedback-table">
            <thead>
              <tr>
                <th className="hidden-sm">Username</th>
                <th className="hidden-sm">Email</th>
                <th>Feedback</th>
                <th className="hidden-sm">Date</th>
                {/* <th>Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {feedbacks.map((item, index) => (
                <tr key={index}>
                  <td className="hidden-sm">{item.userId?.username}</td>
                  <td className="hidden-sm">{item.userId?.email}</td>
                  <td>
                    <div className="feedback-content">
                      <div>{item.message}</div>
                    </div>
                  </td>
                  <td className="hidden-sm">{item.createdAt}</td>
                  <td>
                    <div className="action-buttons">                 
                      <button
                        className="delete-feedback-btn" 
                        onClick={() => handleDeleteFeedback}
                   
                      >
                     Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdditionalFeedback;
