import React, { useEffect, useState } from "react";
import "../style/nav.scss";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { RiLogoutCircleRLine } from "react-icons/ri";

import { FaRegUser } from "react-icons/fa";

function Nav() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSignedIn, setIsSignedIn] = useState(false);

  // Check if user is signed in on initial load
  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsSignedIn(!!token); // Set isSignedIn to true if token exists
  }, []);

  // Logout function
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    toast.success(t("nav.logoutSuccess"));
    setIsSignedIn(false); // Update state to reflect that the user is logged out
    navigate("/");
  };

  // Login function
  const login = () => {
    navigate("/login"); // Redirect to the login page
  };

  // Language selection function
  const selectLang = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  // Set selected language on initial load
  useEffect(() => {
    
    if(i18n.language!='en' && i18n.language!='ar'){
      i18n.changeLanguage("en");
    }
    for (let index = 0; index < document.getElementsByClassName("lang").length; index++) {
      const element = document.getElementsByClassName("lang")[index];
      if (element.value === i18n.language) {
        element.setAttribute("selected", "true");
      }
    }
  }, [i18n.language]);

  // Check if the current path is the home screen
  const isHomeScreen = location.pathname === "/";

  return (
    <div>
      <nav>
        <h2>Trivia</h2>
        {isHomeScreen && (
          <div className="side-cnt">
            <select id="sel-lang" className="select-lang" onChange={selectLang}>
              <option value="en" className="lang">
                English
              </option>
              <option value="ar" className="lang">
                العربية
              </option>
            </select>
            {isSignedIn ? (
              <button onClick={logout}>
                <RiLogoutCircleRLine />
                 
              </button>
            ) : (
              <button onClick={login}>
                <FaRegUser />
               
              </button>
            )}
          </div>
        )}
      </nav>
    </div>
  );
}

export default Nav;
