import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../style/SignInForm.scss";
import { signIn, signInAsGuest } from "../services/authService";
import toast from "react-hot-toast";
import FullScreenLoader from "./FullScreenLoader"; // Import FullScreenLoader
import { useTranslation } from "react-i18next";

const SignInForm = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [guestUsername, setGuestUsername] = useState("");
  const [isGuestMode, setIsGuestMode] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      const response = await signIn(email, password);
      localStorage.setItem("token", response.token);
      localStorage.setItem("user", JSON.stringify(response.user));
      toast.success(t("signInForm.signIn"));
      if(response.user.isAdmin){
        window.location.href = "/admin";
      }else{
        window.location.href = "/";
      }
    } catch (error) {
      toast.error(t("signInForm.invalidEmailOrPassword"));
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleGuestLogin = async (e) => {
    e.preventDefault();
    // if (!guestUsername.trim()) {
    //   toast.error(t("signInForm.enterUniqueUsername"));
    //   return;
    // }
    setLoading(true); // Start loading
    try {
      const response = await signInAsGuest();
      localStorage.setItem("token", response.token);
      localStorage.setItem("user", JSON.stringify(response.user));
      toast.success(t("signInForm.continueAsGuest"));
      window.location.href = "/";
    } catch (error) {
      toast.error(t("signInForm.failedToSignInAsGuest"));
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const toggleGuestMode = () => {
    setIsGuestMode(!isGuestMode);
    toast.dismiss(); // Dismiss any existing toasts
  };

  return (
    <div className="signin-container">
      {loading && <FullScreenLoader />} {/* Show loader when loading */}
      <div className="signin-wrapper">
        <div className="signin-header">
          <h2 className="signin-title">
            {isGuestMode
              ? t("signInForm.guestTitle")
              : t("signInForm.signInTitle")}
          </h2>
          {!isGuestMode && (
            <p className="signin-subtitle">
              <Link className="signin-link" to="/register">
                {t("signInForm.signInSubtitle")}
              </Link>
            </p>
          )}
        </div>

        {isGuestMode ? (
          <form className="signin-form" onSubmit={handleGuestLogin}>
            <div className="signin-input-group">
              <label className="signin-label" htmlFor="guestUsername">
                {t("signInForm.chooseUsername")}
              </label>
              <input
                className="signin-input"
                id="guestUsername"
                required
                placeholder={t("signInForm.enterUniqueUsername")}
                type="text"
                name="guestUsername"
                value={guestUsername}
                onChange={(e) => setGuestUsername(e.target.value)}
              />
            </div>
            <div>
              <button className="signin-button" type="submit" disabled={loading}>
                {t("signInForm.continueAsGuest")}
              </button>
            </div>
          </form>
        ) : (
          <form className="signin-form" onSubmit={handleSubmit}>
            <div className="signin-input-group">
              <label className="signin-label" htmlFor="email">
                {t("signInForm.emailAddress")}
              </label>
              <input
                className="signin-input"
                id="email"
                autoComplete="email"
                required
                placeholder={t("signInForm.emailAddress")}
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="signin-input-group">
              <label className="signin-label" htmlFor="password">
                {t("signInForm.password")}
              </label>
              <input
                className="signin-input"
                id="password"
                autoComplete="current-password"
                required
                placeholder={t("signInForm.password")}
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <label
              className="error-message"
              onClick={() => navigate("/forget-pass")}
              style={{
                paddingLeft: "5px",
                textAlign: "right",
                paddingTop: "5px",
              }}
            >
              <b>{t("signInForm.forgetPassword")}</b>
            </label>
            <div>
              <button className="signin-button" type="submit" disabled={loading}>
                {t("signInForm.signIn")}
              </button>
            </div>
          </form>
        )}
        <div className="signin-divider">
          <div className="divider-line"></div>
          <div className="divider-text">{t("signInForm.or")}</div>
          <div className="divider-line"></div>
        </div>
        <div>
          <button className="guest-button" onClick={handleGuestLogin} disabled={loading}>
            {isGuestMode
              ? t("signInForm.backToSignIn")
              : t("signInForm.continueAsGuestButton")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignInForm;
