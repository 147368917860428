import React, { useEffect, useState } from "react";
import AdminSidebar from "../../components/admin/AdminSidebar";
import "../../style/adminstyle/UserList.scss";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const UserList = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/get-users`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      console.log("Users fetched:", response.data);
      setUsers(response.data.users);
    } catch (error) {
      if (error.response && error.response.status >= 400) {
        navigate('/');
      } else {
        console.error('Failed to fetch users', error);
      }
    }
  }

  const delUser = async (userId) => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/auth/del-user/${userId}/`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      console.log("User deleted:", response.data);
      fetchUsers();
    } catch (error) {
      if (error.response && error.response.status >= 400) {
        navigate('/');
      } else {
        console.error('Failed to delete user', error);
      }
    }
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="admin-container">
      <AdminSidebar />
      <div className="user-list__container">
        <div className="user-list__header">
          <h1 className="user-list__title">User List</h1>
          <div className="user-list__controls">
            <input
              className="user-list__search"
              placeholder="Search by name or email"
              type="text"
              value=""
            />
          </div>
        </div>
        <div className="user-list__table-wrapper">
          <table className="user-list__table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>

                <th>Role</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={index}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td>
                    <div className="user-list__actions">
                     
                      <button className="user-list__delete-btn" onClick={() => delUser(user.id)}>Delete</button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserList;
