import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import "../style/QuizMCQ.scss";

const QuizMCQ = ({ question, timer, questionIndex, isSubmited, handleSubmitMCQ, options }) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  return (
    <div className="quiz-container">
      <div className="question-no-cnt">
        <p>{t('quizMCQ.question')}</p>
        <div>
          <span className="current-question">{questionIndex}</span>/
          <span className="total-question">{t('quizMCQ.totalQuestions')}</span>
        </div>
      </div>

      <div className="quiz-card">
        <div className="quiz-header">
          <div className="quiz-timer">{t('quizMCQ.timeLeft', { timer })}</div>
          <h2 className="quiz-title">{question}</h2>
        </div>
        <div className="quiz-options">
          {options.map((option, index) => (
            <div
              key={index}
              className={`quiz-option ${
                selectedOption === option.id ? "selected" : ""
              }`}
              onClick={() => handleOptionClick(option.id)}
            >
              <button
                type="button"
                role="checkbox"
                aria-checked={selectedOption === option.id}
                data-state={selectedOption === option.id ? "checked" : "unchecked"}
                value={option.id}
                className={`quiz-checkbox ${
                  selectedOption === option.id ? "checked" : ""
                }`}
                id={`option-${index}`}
              ></button>
              <div>
                <label className="quiz-label" htmlFor={`option-${index}`}>
                  {option.text}
                </label>
              </div>
            </div>
          ))}
        </div>
        <div className="btn-cnt">
          <button className="button" disabled={isSubmited} onClick={() => handleSubmitMCQ(selectedOption)}>
            {t('quizMCQ.submitButton')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuizMCQ;
