import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../style/CreateRoomDialog.css";
import FullScreenLoader from "./FullScreenLoader";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
} from "@mui/material";
import { useTranslation } from 'react-i18next';

const CreateRoom = () => {
  const { t } = useTranslation();
  const [inputTime, setInputTime] = useState(30);
  const [mcqTime, setMcqTime] = useState(60);
  const [language, setLanguage] = useState("english");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  const marks = [
    { value: 30, label: t('createRoom.sliderMarks.30sec') },
    { value: 45, label: t('createRoom.sliderMarks.45sec') },
    { value: 60, label: t('createRoom.sliderMarks.60sec') },
  ];

  const token = localStorage.getItem('token');
  var user = localStorage.getItem('user');

  if (!token || !user) {
    console.error("No token found. User might not be authenticated.");
    navigate(`/login`);
    return;
  }
  user = JSON.parse(user);

  const valuetext = (value) => `${value}sec`;

  const handleCreateRoom = async (event) => {
    event.preventDefault();
    setLoading(true); // Start loading

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/rooms/create`, 
        {
          inputTime,
          name,
          mcqTime,
          language
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      const { roomId } = response.data;
      
      // Navigate to the game room
      navigate(`/play/${roomId}`, { state: { name } });
    } catch (error) {
      console.error("Error creating room:", error);
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized. Token might be invalid or expired.");
        navigate(`/login`);
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="dialog">
      {loading && <FullScreenLoader />} {/* Show full screen loader when loading */}
      <div className="dialog-content">
        <div className="dialog-header">
          <h2>{t('createRoom.header')}</h2>
        </div>
        <form onSubmit={handleCreateRoom}>
          <div className="form-group">
            <label htmlFor="name">{t('createRoom.userNameLabel')}</label>
            <TextField
              id="outlined-basic"
              label={t('createRoom.userNameLabel')}
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="inputTime">{t('createRoom.inputTimeLabel')}</label>
            <Box sx={{ width: 160 }}>
              <Slider
                aria-label={t('createRoom.inputTimeLabel')}
                value={inputTime}
                getAriaValueText={valuetext}
                step={null}
                valueLabelDisplay="auto"
                marks={marks}
                min={30}
                max={60}
                onChange={(e, newValue) => setInputTime(newValue)}
              />
            </Box>
          </div>
          <div className="form-group">
            <label htmlFor="mcqTime">{t('createRoom.mcqTimeLabel')}</label>
            <Box sx={{ width: 160 }}>
              <Slider
                aria-label={t('createRoom.mcqTimeLabel')}
                value={mcqTime}
                getAriaValueText={valuetext}
                step={null}
                valueLabelDisplay="auto"
                marks={marks}
                min={30}
                max={60}
                onChange={(e, newValue) => setMcqTime(newValue)}
              />
            </Box>
          </div>
          <div className="form-group">
            <label htmlFor="language">{t('createRoom.languageLabel')}</label>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="language-selection-label"
                name="language-selection"
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
              >
                <FormControlLabel
                  value="english"
                  control={
                    <Radio
                      sx={{
                        color: "gray",
                        "&.Mui-checked": {
                          color: "#013917",
                        },
                      }}
                    />
                  }
                  label={t('createRoom.languageOptions.english')}
                />
                <FormControlLabel
                  value="arabic"
                  control={
                    <Radio
                      sx={{
                        color: "gray",
                        "&.Mui-checked": {
                          color: "#013917",
                        },
                      }}
                    />
                  }
                  label="العربية"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="dialog-footer">
            <button type="submit" className="button" disabled={loading}>
              {t('createRoom.confirmButton')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateRoom;
