 import React, { useState, useCallback } from "react";
import { TextField } from "@mui/material";
import { useTranslation } from 'react-i18next';
import "../style/FeedbackForm.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const FeedbackForm = ({ questions, name, roomId }) => {
  const { t } = useTranslation();
  const [feedback, setFeedback] = useState("");
  const [questionFeedbacks, setQuestionFeedbacks] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const handleFeedbackChange = useCallback((event) => {
    console.log("Additional feedback changed:", event.target.value);
    setFeedback(event.target.value);
  }, []);

  const handleQuestionFeedbackChange = useCallback((questionId, value) => {
    console.log("Question feedback changed:", questionId, value);
    setQuestionFeedbacks(prev => ({
      ...prev,
      [questionId]: value
    }));
  }, []);

  const submitFeedback = async () => {
    setIsSubmitting(true);
    setSubmitError(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/feedback/submit-feedback`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          questionFeedbacks,
          additionalFeedback: feedback
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit feedback');
      }

      setQuestionFeedbacks({});
      setFeedback("");
      toast.success("Thank You! for your Feedback.");
      navigate(`/play/${roomId}`, { state: { name } });
      // window.location.reload();
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setSubmitError("Something went wrong.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSkip = () => {
    navigate(`/play/${roomId}`, { state: { name } });
    // window.location.reload();
  };

  return (
    <div className="feedback-form">
      <div className="feedback-header">
        <h1 className="feedback-title">{t('feedbackForm.title')}</h1>
        <p className="feedback-subtitle">{t('feedbackForm.subtitle')}</p>
      </div>
      <div className="feedback-content">
        <div className="accordion">
          <div className="accordion-section">
            <h3 className="accordion-header"></h3>
            {questions.map((q) => (
              <div className="accordion-body" key={q.questionId}>
                <p className="accordion-text">{q.question}</p>
                <label className="correct-ans">{t('feedbackForm.correctanswer')}: </label>
                <span>{q.answer}</span>
                <div className="input-btn">
                  <TextField
                    fullWidth
                    label={t('feedbackForm.feedbackLabel')}
                    id={`feedback-${q.questionId}`}
                    size="small"
                    value={questionFeedbacks[q.questionId] || ''}
                    onChange={(e) => handleQuestionFeedbackChange(q.questionId, e.target.value)}
                    onFocus={() => console.log(`Focused on question ${q.questionId} feedback`)}
                    onBlur={() => console.log(`Blurred from question ${q.questionId} feedback`)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="feedback-additional">
          <label className="feedback-label" htmlFor="additional-feedback">
            {t('feedbackForm.additionalFeedbackLabel')}
          </label>
          <textarea
            className="feedback-textarea"
            id="additional-feedback"
            placeholder={t('feedbackForm.additionalFeedbackPlaceholder')}
            value={feedback}
            onChange={handleFeedbackChange}
            onFocus={() => console.log('Focused on additional feedback')}
            onBlur={() => console.log('Blurred from additional feedback')}
          ></textarea>
        </div>
      </div>
      {submitError && <p className="error-message">{submitError}</p>}
      <div className="button-container">
        <button className="button button-outline" onClick={handleSkip} disabled={isSubmitting}>
          {t('feedbackForm.skipButton')}
        </button>
        <button className="button button-primary" onClick={submitFeedback} disabled={isSubmitting}>
          {isSubmitting ? t('feedbackForm.submitting') : t('feedbackForm.submitButton')}
        </button>
      </div>
    </div>
  );
};

export default FeedbackForm;