import React, { useEffect, useState } from "react";
import { AiFillFileText } from "react-icons/ai";
import { FaChartBar, FaChartLine, FaChartPie } from "react-icons/fa";
import { HiMenuAlt4 } from "react-icons/hi";
import { IoIosPeople } from "react-icons/io";
import { RiDashboardFill, RiShoppingBag3Fill } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import "../../style/adminstyle/_mediaquery.scss"; 
import { MdFeedback } from "react-icons/md";
import { VscSettings } from "react-icons/vsc";
import { IoMdCloudUpload } from "react-icons/io";

const AdminSidebar = () => {
  const location = useLocation();

  const [showModal, setShowModal] = useState(false);
  const [phoneActive, setPhoneActive] = useState(window.innerWidth < 1100);

  const resizeHandler = () => {
    setPhoneActive(window.innerWidth < 1100);
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  return (
    <>
      {phoneActive && (
        <button id="hamburger" onClick={() => setShowModal(true)}>
          <HiMenuAlt4 />
        </button>
      )}

      <aside
        style={
          phoneActive
            ? {
                width: "20rem",
                height: "100vh",
                position: "fixed",
                top: 0,
                left: showModal ? "0" : "-30rem",
                transition: "all 0.5s",
              }
            : {}
        }
      >
        {/* <h2>Logo.</h2> */}
        <DivOne location={location} />

        {phoneActive && (
          <button id="close-sidebar" onClick={() => setShowModal(false)}>
            Close
          </button>
        )}
      </aside>
    </>
  );
};

const DivOne = ({ location }) => (
  <div>
    <h5>Dashboard</h5>
    <ul>
      <Li url="/admin" text="Question English" Icon={RiDashboardFill} location={location} />
      <Li url="/admin/question-ar" text="Question Arabic" Icon={RiDashboardFill} location={location} />
      <Li url="/admin/feedback" text="QNA Feedback" Icon={MdFeedback } location={location} />
      <Li url="/admin/user" text="Users" Icon={IoIosPeople} location={location} />
      <Li url="/admin/upload" text="Upload" Icon={IoMdCloudUpload} location={location} />
      <Li url="/admin/setting" text="Game Setting" Icon={VscSettings} location={location} />
      <Li url="/admin/additional-feedback" text="Feedback" Icon={MdFeedback} location={location} />
    
    </ul>
  </div>
);



const Li = ({ url, text, location, Icon }) => (
  <li
    style={{
      backgroundColor: location.pathname === url ? "rgba(13, 255, 0, 0.1)" : "white",
    }}
  >
    <Link
      to={url}
      style={{
        color: location.pathname === url ? "#013917" : "black",
      }}
    >
      <Icon />
      {text}
    </Link>
  </li>
);


export default AdminSidebar;
