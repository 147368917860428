import React, { useState } from "react";
import "../style/LeaderboardComponent.scss";
import User from "../Images/user1.png";
import { useTranslation } from 'react-i18next';
import FeedbackForm from "./feedback-form";
import {useLocation} from 'react-router-dom';

const FinalLeaderboard = () => {
  const [showFeedback, setShowFeedback] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  
  const questions = location.state.questions;
  const players = location.state.players;
  console.log(players);

  const handleShowFeedback = () => {
    setShowFeedback(true);
  };

  if(showFeedback){
    return <FeedbackForm questions={questions} name={location.state.name} roomId={location.state.roomId} />
  }

  const sortedPlayers = [...players].sort((a, b) => b.points - a.points);
  const firstPlace = sortedPlayers[0];
  const secondPlace = sortedPlayers[1];
  const thirdPlace = sortedPlayers[2];

  return (
    <div className="leaderboard-container">
      <main className="leaderboard-main">
        <h2 className="leaderboard-title">{t('finalleaderboard.highScore')}</h2>
        <div className="leaderboard">
          {secondPlace?<div className="card card-small">
            <div className="avatar-container">
              <img className="avatars" alt="User" src={User} />
              <div className="badge secondary-badge">2</div>
            </div>
            <div className="name">{secondPlace.name}</div>
            <div className="score">{secondPlace.points}</div>
          </div>:<></>}
          <div className="card card-large">
            <div className="avatar-container">
              <img className="avatar-large" alt="User" src={User} />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="star-icon"
              >
                <path d="M11.562 3.266a.5.5 0 0 1 .876 0L15.39 8.87a1 1 0 0 0 1.516.294L21.183 5.5a.5.5 0 0 1 .798.519l-2.834 10.246a1 1 0 0 1-.956.734H5.81a1 1 0 0 1-.957-.734L2.02 6.02a.5.5 0 0 1 .798-.519l4.276 3.664a1 1 0 0 0 1.516-.294z"></path>
                <path d="M5 21h14"></path>
              </svg>
              <div className="badge primary-badge">1</div>
            </div>
            <div className="name">{firstPlace.name}</div>
            <div className="primary-score">{firstPlace.points}</div>
          </div>
          {thirdPlace?<div className="card card-small">
            <div className="avatar-container">
              <img className="avatars" alt="User" src={User} />
              <div className="badge secondary-badge">3</div>
            </div>
            <div className="name">{thirdPlace.name}</div>
            <div className="score">{thirdPlace.points}</div>
          </div>:<></>}
        </div>

        <div className="leaderboard-list">
          <h4 className="leaderboard-list-title">{t('finalleaderboard.leaderboard')}</h4>
          {sortedPlayers.map((player, index) => (
            <div key={index} className="leaderboard-item">
              <div className="leaderboard-side">
                <span className="leaderboard-rank">{index + 1}</span>
                <span className="leaderboard-item-avatar">
                  <img alt={player.name} src={User} />
                </span>
                <div className="leaderboard-item-cnt">
                  <span className="leaderboard-item-name">{player.name}</span>
                </div>
              </div>
              <div className="leaderboard-item-score">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M6 9H4.5a2.5 2.5 0 0 1 0-5H6"></path>
                  <path d="M18 9h1.5a2.5 2.5 0 0 0 0-5H18"></path>
                  <path d="M4 22h16"></path>
                  <path d="M10 14.66V17c0 .55-.47.98-.97 1.21C7.85 18.75 7 20.24 7 22"></path>
                  <path d="M14 14.66V17c0 .55.47.98.97 1.21C16.15 18.75 17 20.24 17 22"></path>
                  <path d="M18 2H6v7a6 6 0 0 0 12 0V2Z"></path>
                </svg>
                <span>{t('finalleaderboard.score')}: {player.points}</span>
              </div>
            </div>
          ))}
          <div className="btn-cnt">
            <button className="button" onClick={handleShowFeedback}>
              {t('finalleaderboard.next')}
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default FinalLeaderboard;
