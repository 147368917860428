import React, { useState } from 'react';
import CreateRoom from '../components/CreateRoom';
import JoinRoom from '../components/JoinRoom';
import '../style/home.scss';
import "../App.css"
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Home = ({ setRoomId, setIsHost, setPlayerName, onRoomCreated, onJoinRoom }) => {
  const [showCreateRoom, setShowCreateRoom] = useState(false);
  const [showJoinRoom, setShowJoinRoom] = useState(false);
  const navigate = useNavigate();
  const {t}=useTranslation()

  const showCreateDialog = () => {
    if(localStorage.getItem('token')){
      setShowCreateRoom(true)
    }else{
      navigate('/login');
    }
  }
  const showJoinDialog = () => {
    if(localStorage.getItem('token')){
      setShowJoinRoom(true)
    }else{
      navigate('/login');
    }
  }
  const logout = () => {
    // Clear token and user data from localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('user');

    // Redirect to login page
    navigate('/login');
  };



  return (
    <div className="home-page">
    <section className="home-header">
      <div className="home-header__text">
        <h1 className="home-header__title">{t('title')}</h1>
        <p className="home-header__description">
          {t('homeHeader.description')}
        </p>
      </div>
      <div className="home-header__buttons">
        <button onClick={showCreateDialog} className="btn btn--create">
          {t('buttons.createGame')}
        </button>
        <button onClick={showJoinDialog} className="btn btn--join">
          {t('buttons.joinGame')}
        </button>
      </div>
    </section>
    <section className="home-instructions">
      <div className="home-instructions__text">
        <h2 className="home-instructions__title">{t('instructions.title')}</h2>
        <p className="home-instructions__description">
          {t('instructions.description')}
        </p>
      </div>
      <div className="home-instructions__steps">
        <div className="step">
          <div className="step__number">1</div>
          <div>
            <h3 className="step__title">{t('instructions.steps.step1.title')}</h3>
            <p className="step__description">{t('instructions.steps.step1.description')}</p>
          </div>
        </div>
        <div className="step">
          <div className="step__number">2</div>
          <div>
            <h3 className="step__title">{t('instructions.steps.step2.title')}</h3>
            <p className="step__description">{t('instructions.steps.step2.description')}</p>
          </div>
        </div>
        <div className="step">
          <div className="step__number custom-circle">3</div>
          <div>
            <h3 className="step__title">{t('instructions.steps.step3.title')}</h3>
            <p className="step__description">{t('instructions.steps.step3.description')}</p>
          </div>
        </div>
      </div>
    </section>
    {showCreateRoom && (
      <div className="modal">
        <div className="modal__content">
          <button className="modal__close" onClick={() => setShowCreateRoom(false)}>
           X
          </button>
          <CreateRoom />
        </div>
      </div>
    )}
    {showJoinRoom && (
      <div className="modal">
        <div className="modal__content">
          <button className="modal__close" onClick={() => setShowJoinRoom(false)}>
           X
          </button>
          <JoinRoom />
        </div>
      </div>
    )}
  </div>
  );
};

export default Home;
