import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";

import Room from "./page/Room";
import Home from "./page/home";
import QuestionInputPage from "./components/QuestionInput";
import Admin from "./page/admin/admin";
import Question from "./page/Question";
import SignInForm from "./components/Signin";
import Nav from "./components/nav";
import SignUpForm from "./components/Signup";
import ForgotPasswordForm from "./components/ForgetPass";
import Leaderboard from "./components/LeaderboardComponent";

import { Toaster } from "react-hot-toast";
import FinalLeaderboard from "./components/FinalLeaderboardComponent";
import FeedbackForm from "./components/feedback-form";
import UserList from "./page/admin/UserList";
import Feedback from "./page/admin/feedback-admin-";
import UploadQuestion from "./page/admin/uploadquestion";
import GameSettings from "./page/admin/gameSettings";
import AdditionalFeedback from "./page/admin/feedback-admin-additional";
import QuestionArabic from "./page/admin/admin-question-ar";


const App = () => {
  const [roomId, setRoomId] = useState("");
  const [isHost, setIsHost] = useState(false);
  const [playerName, setPlayerName] = useState("");
  const navigate = useNavigate();

  const handleRoomCreated = (roomId, playerName) => {
    setRoomId(roomId);
    setIsHost(true);
    setPlayerName(playerName);
    navigate("/room");
  };

  const handleJoinRoom = (roomId, playerName) => {
    setRoomId(roomId);
    setPlayerName(playerName);
    navigate("/room");
  };

  return (
    <>
      <Nav />
      <Routes>
        <Route
          path="/"
          element={
            <Home
              setRoomId={setRoomId}
              setIsHost={setIsHost}
              setPlayerName={setPlayerName}
              onRoomCreated={handleRoomCreated}
              onJoinRoom={handleJoinRoom}
            />
          }
        />
        <Route path="/play/:roomId" element={<Room />} />
        <Route path="/question" element={<QuestionInputPage />} />
        <Route path="/mcq" element={<Question />} />
        <Route path="/login" element={<SignInForm />} />
        <Route path="/register" element={<SignUpForm />} />
        <Route path="/forget-pass" element={<ForgotPasswordForm />} />
        <Route path="/leaderboard" element={<FinalLeaderboard />} />
        <Route path="/leaderboards" element={<FeedbackForm />} />-

        {/* //admin// */}
        <Route path="/admin/feedback" element={<Feedback />} />
        <Route path="/admin/user" element={<UserList />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/admin/question-ar" element={<QuestionArabic />} />
        <Route path="/admin/upload" element={<UploadQuestion/>} />
        <Route path="/admin/setting" element={<GameSettings/>} />
        <Route path="/admin/additional-feedback" element={<AdditionalFeedback/>} />
      </Routes>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default App;
