import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import "../style/QuestionInput.scss";

const QuestionInputPage = ({ question, timer, questionIndex, errorMessage, isSubmited, handleSubmitAnswer }) => {
  const { t } = useTranslation();
  const [answer, setAnswer] = useState("");

  const submitAnswer = () => {
    if (answer !== "") {
      handleSubmitAnswer(answer);
    }
  }

  return (
    <div className="qes-input-container">
      <div className="content">
        <div className="card">
          <div className="text-center">
            <h2 className="question">{question || t('questionInputPage.loadingQuestion')}</h2>
          </div>
          <div>
            <label className="fakeanswer-label" htmlFor="answer">
              {t('questionInputPage.enterAnswer')}
            </label>
            <input
              type="text"
              placeholder={t('questionInputPage.placeholderAnswer')}
              className="input"
              disabled={isSubmited}
              onChange={(e) => setAnswer(e.target.value)}
            />
            <label style={{ color: 'red', fontSize: '0.875rem' }}>
              {errorMessage}
            </label>
          </div>
          <div className="footer">
            <div className="timer">
              {t('questionInputPage.timeLeft')} <span className="timer-sec">{timer}s</span>
            </div>
            <button className="button" disabled={isSubmited} onClick={submitAnswer}>
              {t('questionInputPage.submitButton')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionInputPage;
