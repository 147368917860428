import React, { useState } from "react";
import "../style/SignInForm.scss";
import { Link, useNavigate } from "react-router-dom";
import { registerUser } from "../services/authService";
import toast from "react-hot-toast";
import FullScreenLoader from "./FullScreenLoader"; // Import FullScreenLoader
import { useTranslation } from 'react-i18next';

const SignUpForm = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simple validation
    if (!formData.username || !formData.email || !formData.password) {
      toast.error(t('signUpForm.allFieldsRequired'));
      return;
    }

    setLoading(true); // Start loading
    try {
      const errorData = await registerUser(formData);
      toast.success(errorData);
      window.location.href = "/login";
    } catch (err) {
      const errorMessage = err.error || t('signUpForm.registrationFailed');
      toast.error(errorMessage);
      console.log(err);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="signin-container">
      {loading && <FullScreenLoader />} {/* Show loader when loading */}
      <div className="signin-wrapper">
        <div className="signin-header">
          <h2 className="signin-title">{t('signUpForm.title')}</h2>
          <p className="signin-subtitle">
            {t('signUpForm.subtitle')}
            <Link className="signin-link" to="/login">
              {t('signUpForm.link')}
            </Link>
          </p>
        </div>
        <form className="signin-form" onSubmit={handleSubmit}>
          <div className="signin-input-group">
            <label className="signin-label" htmlFor="username">
              {t('signUpForm.usernameLabel')}
            </label>
            <input
              className="signin-input"
              id="username"
              autoComplete="username"
              required
              placeholder={t('signUpForm.usernamePlaceholder')}
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
            />
          </div>
          <div className="signin-input-group">
            <label className="signin-label" htmlFor="email">
              {t('signUpForm.emailLabel')}
            </label>
            <input
              className="signin-input"
              id="email"
              autoComplete="email"
              required
              placeholder={t('signUpForm.emailPlaceholder')}
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="signin-input-group">
            <label className="signin-label" htmlFor="password">
              {t('signUpForm.passwordLabel')}
            </label>
            <input
              className="signin-input"
              id="password"
              autoComplete="new-password"
              required
              placeholder={t('signUpForm.passwordPlaceholder')}
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
          </div>
          <div>
            <button className="signin-button" type="submit" disabled={loading}>
              {t('signUpForm.submitButton')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUpForm;
