// FullScreenLoader.js
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "../style/admin.scss"; // We'll define styles in this file

const FullScreenLoader = () => {
  return (
    <div className="fullscreen-loader">
      <CircularProgress size={80} />
    </div>
  );
};

export default FullScreenLoader;
