import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useTranslation } from 'react-i18next';
import { forgotPassword, resetPassword } from "../services/authService";
import FullScreenLoader from "./FullScreenLoader"; // Import FullScreenLoader

const ForgetPasswordForm = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate();

  const handleSendOTP = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      await forgotPassword(email);
      setStep(2);
      toast.success(t('forgetPassword.sendOtpSuccess'));
    } catch (error) {
      toast.error(t('forgetPassword.sendOtpError'));
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      toast.error(t('forgetPassword.passwordMismatch'));
      return;
    }
    setLoading(true); // Start loading
    try {
      await resetPassword(email, otp, newPassword);
      toast.success(t('forgetPassword.resetSuccess'));
      setStep(3);
    } catch (error) {
      toast.error(t('forgetPassword.resetError'));
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="signin-container">
      {loading && <FullScreenLoader />} {/* Show full screen loader when loading */}
      <div className="signin-wrapper">
        <div className="signin-header">
          <h2 className="signin-title">{t('forgetPassword.title')}</h2>
          <p className="signin-subtitle">
            {t('forgetPassword.subtitle')}
            <Link className="signin-link" to="/login">{t('forgetPassword.signInButton')}</Link>
          </p>
        </div>

        {step === 1 && (
          <form className="signin-form" onSubmit={handleSendOTP}>
            <div className="signin-input-group">
              <label className="signin-label" htmlFor="email">
                {t('forgetPassword.emailLabel')}
              </label>
              <input
                className="signin-input"
                id="email"
                required
                placeholder={t('forgetPassword.emailPlaceholder')}
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <button className="signin-button" type="submit" disabled={loading}>
                {t('forgetPassword.sendOtpButton')}
              </button>
            </div>
          </form>
        )}

        {step === 2 && (
          <form className="signin-form" onSubmit={handleResetPassword}>
            <div className="signin-input-group">
              <label className="signin-label" htmlFor="otp">
                {t('forgetPassword.otpLabel')}
              </label>
              <input
                className="signin-input"
                id="otp"
                required
                placeholder={t('forgetPassword.otpPlaceholder')}
                type="text"
                name="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>
            <div className="signin-input-group">
              <label className="signin-label" htmlFor="newPassword">
                {t('forgetPassword.newPasswordLabel')}
              </label>
              <input
                className="signin-input"
                id="newPassword"
                required
                placeholder={t('forgetPassword.newPasswordPlaceholder')}
                type="password"
                name="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="signin-input-group">
              <label className="signin-label" htmlFor="confirmPassword">
                {t('forgetPassword.confirmPasswordLabel')}
              </label>
              <input
                className="signin-input"
                id="confirmPassword"
                required
                placeholder={t('forgetPassword.confirmPasswordPlaceholder')}
                type="password"
                name="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div>
              <button className="signin-button" type="submit" disabled={loading}>
                {t('forgetPassword.resetPasswordButton')}
              </button>
            </div>
          </form>
        )}

        {step === 3 && (
          <div className="signin-form">
            <div>
              <button
                onClick={() => navigate("/login")}
                className="signin-button"
                type="button"
              >
                {t('forgetPassword.signInButton')}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgetPasswordForm;
