import React, { useState } from "react";
import "../style/LeaderboardComponent.scss";
import User from "../Images/user1.png";
import FeedbackForm from "./feedback-form";
import { useTranslation } from 'react-i18next';

const Leaderboard = ({
  question,
  correctAnswer,
  isHost,
  players,
  nextQ,
  playerMessages,
  reference
}) => {
  const { t } = useTranslation();
  const [showReference, setShowReference] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  const toggleReference = () => {
    setShowReference(!showReference);
  };

  const handleShowFeedback = () => {
    setShowFeedback(true);
  };

  if (showFeedback) {
    return <FeedbackForm question={question} />;
  }

  const sortedPlayers = [...players].sort((a, b) => b.points - a.points);
  const topPlayer = sortedPlayers[0];

  return (
    <div className="leaderboard-container">
      <header className="leaderboard-header">
        <label className="correct-ans">{t('leaderboard.correctAnswer')}</label>
        <span>{correctAnswer}</span>
        <button onClick={toggleReference} className="info-button">i</button>
      </header>
      {showReference && (
        <div className="reference-info animated">
          <p>{correctAnswer}</p>
        </div>
      )}
      <main className="leaderboard-main">
        <h2 className="leaderboard-title">{t('leaderboard.highScore')}</h2>
        <span className="leaderboard-avatar">
          <img alt="User" src={User} />
        </span>
        <h3 className="leaderboard-player-name">{topPlayer.name}</h3>
        <p className="leaderboard-score">{topPlayer.points} Points</p>
        <div className="leaderboard-list">
          <h4 className="leaderboard-list-title">{t('leaderboard.leaderboard')}</h4>
          {sortedPlayers.map((player, index) => (
            <div key={index} className="leaderboard-item">
              <div className="leaderboard-side">
                <span className="leaderboard-rank">{index + 1}</span>
                <span className="leaderboard-item-avatar">
                  <img alt={player.name} src={User} />
                </span>
                <div className="leaderboard-item-cnt">
                  <span className="leaderboard-item-name">{player.name}</span>
                  <span className="leaderboard-item-answer">
                   ({playerMessages[player.userId] ??
                      player.name + " has not selected any answer"})
                  </span>
                </div>
              </div>
              <div className="leaderboard-item-score">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M6 9H4.5a2.5 2.5 0 0 1 0-5H6"></path>
                  <path d="M18 9h1.5a2.5 2.5 0 0 0 0-5H18"></path>
                  <path d="M4 22h16"></path>
                  <path d="M10 14.66V17c0 .55-.47.98-.97 1.21C7.85 18.75 7 20.24 7 22"></path>
                  <path d="M14 14.66V17c0 .55.47.98.97 1.21C16.15 18.75 17 20.24 17 22"></path>
                  <path d="M18 2H6v7a6 6 0 0 0 12 0V2Z"></path>
                </svg>
                <span>{t('leaderboard.score')} {player.points}</span>
              </div>
            </div>
          ))}
          <div className="btn-cnt">
            {isHost ? (
              <button className="button" onClick={nextQ}>
                {t('leaderboard.nextButton')}
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Leaderboard;
