import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import io from "socket.io-client";
import "../style/Room.scss";
import QuestionInputPage from "../components/QuestionInput";
import QuizMCQ from "../components/QuizMCQ";
import WaitingArea from "./WaitingArea";
import Leaderboard from "../components/LeaderboardComponent";
import FinalLeaderboard from "../components/FinalLeaderboardComponent";
import toast from "react-hot-toast";
import { useTranslation } from 'react-i18next';
import {useLocation} from 'react-router-dom';

const Room = ({ playerName }) => {
  const { i18n } = useTranslation();
  const location = useLocation();

  const [page, setPage] = useState(5);
  const [socket, setSocket] = useState(null);
  const [timer, setTimer] = useState(false);
  const [isHost, setIsHost] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answer, setAnswer] = useState("");
  const [correctAnswer, setcorrectAnswer] = useState("");
  const [reference, setreference] = useState("");
  const [mcqOptions, setMcqOptions] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [roomDetails, setRoomDetails] = useState({});
  const [gameEnded, setGameEnded] = useState(false);
  const { roomId } = useParams();
  const [players, setPlayers] = useState([]);
  const [playerMessages, setPlayerMessages] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmited, setIsSubmited] = useState(false);
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const newSocket = io(`${process.env.REACT_APP_API_URL}`, {
      auth: { token, roomId, name:location.state.name, language:i18n.language??'en' },
      reconnection: false,
    });

    newSocket.on("connect", () => {
      console.log("Connected to server");
    });

    newSocket.on("connect_error", (err) => {
      console.log(err);
     toast.error(err.message);
      navigate(`/`);
    });

    newSocket.on("disconnect", () => {
      console.log("disconnected");
    });

    newSocket.on("roomJoined", (data) => {
      setPlayers(data.players);
      setIsHost(data.isHost);
      setIsStarted(data.isStarted);
      setCurrentQuestion(data.currentQuestion);
      setCurrentQuestionIndex(data.currentQuestionIndex);
      setRoomDetails(data);
      setPage(data.currentPage);
    });

    newSocket.on("updatePlayers", (updatedPlayers) => {
      setPlayers(updatedPlayers);
    });

    newSocket.on("gameStarted", () => {
      setIsStarted(true);
    });

    newSocket.on("newQuestion", (data) => {
      setCurrentQuestion(data.question);
      setCurrentQuestionIndex(data.questionNumber);
      setAnswer("");
      setMcqOptions([]);
      setPage(1);
    });

    newSocket.on("answerError", (data) => {
      setErrorMessage(data);
    });

    newSocket.on("timer", (data) => {
      setTimer(data);
    });

    newSocket.on("answerSubmitted", (data) => {
      setErrorMessage("");
      setIsSubmited(true);
    });

    newSocket.on("mcqSubmitted", (data) => {
      setErrorMessage("");

      setIsSubmited(true);
    });

    newSocket.on("startMCQ", (data) => {
      setMcqOptions(data.options);
      setIsSubmited(false);
      setPage(2);
    });

    newSocket.on("mcqResults", (data) => {
      setIsSubmited(false);
      setPlayers(data.updatedPlayers);
      setcorrectAnswer(data.correctAnswer);
      setreference(data.reference);

      setPlayerMessages(data.playerChoiceMessages);
      setPage(3);
    });

    newSocket.on("gameEnded", (data) => {
      setPlayers(data.players);
      setAllQuestions(data.questions);
      setGameEnded(true);
      setPage(4);
    });

    newSocket.on("roomClosed", (message) => {
      toast.error(message);
      navigate(`/`);
    });

    newSocket.on("startGameError", (message) => {
      toast.error(message);
    });

    newSocket.on("newHost", (data) => {
      setIsHost(data.hostId === user.id);
      setGameEnded(true);
    });

    setSocket(newSocket);

    return () => newSocket.disconnect();
  }, [token, roomId]);

  const handleReady = useCallback(
    (isReady) => {
      socket.emit("setReady", isReady);
      setIsReady(isReady);
    },
    [socket]
  );

  const handleStartGame = useCallback(() => {
    socket.emit("startGame");
  }, [socket]);

  const disbandRoom = useCallback(() => {
    socket.emit("disbandRoom");
  }, [socket]);

  const handleSubmitAnswer = useCallback(
    (answer) => {
      socket.emit("submitAnswer", answer);
      setAnswer("");
    },
    [socket]
  );

  const handleSubmitMCQ = useCallback(
    (selectedAnswer) => {
      socket.emit("submitMCQ", selectedAnswer);
    },
    [socket]
  );

  const kickOut = useCallback(
    (userId) => {
      socket.emit("kickOut", userId);
    },
    [socket]
  );

  const nextQ = useCallback(() => {
    socket.emit("nextQ");
  }, [socket]);

  const leaveRoom = () => {
    socket.disconnect();
    navigate(`/`);
  };

  if (page === 0) {
    return (
      <WaitingArea
        roomId={roomId}
        roomDetails={roomDetails}
        players={players}
        isHost={isHost}
        isReady={isReady}
        handleReady={handleReady}
        handleStartGame={handleStartGame}
        leaveRoom={leaveRoom}
        disbandRoom={disbandRoom}
        kickOut={kickOut}
      />
    );
  } else if (page === 1) {
    return (
      <QuestionInputPage
        question={currentQuestion}
        timer={timer}
        questionIndex={currentQuestionIndex}
        errorMessage={errorMessage}
        isSubmited={isSubmited}
        handleSubmitAnswer={handleSubmitAnswer}
      />
    );
  } else if (page === 2) {
    return (
      <QuizMCQ
        question={currentQuestion}
        timer={timer}
        questionIndex={currentQuestionIndex}
        errorMessage={errorMessage}
        isSubmited={isSubmited}
        handleSubmitMCQ={handleSubmitMCQ}
        options={mcqOptions}
      />
    );
  } else if (page === 3) {
    return (
      <Leaderboard
        question={currentQuestion}
        isHost={isHost}
        players={players}
        playerMessages={playerMessages}
        nextQ={nextQ}
        correctAnswer={correctAnswer}
        reference={reference}
      />
    );
  } else if (page === 4) {
    navigate(`/leaderboard`, { state: { questions:allQuestions, players, name:location.state.name, roomId } });
    // return (
    //   <FinalLeaderboard
    //     questions={allQuestions}
    //     players={players}
    //     name={location.state.name}
    //     roomCode={roomId}
    //   />
    // );
  } else {
    return <></>;
  }
};

export default Room;
