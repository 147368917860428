import React, { useState } from "react";
import "../style/Room.scss";
import User from "../Images/user1.png";
import { IoMdShare } from "react-icons/io";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const WaitingArea = ({
  roomId,
  playerName,
  roomDetails,
  players,
  isHost,
  isReady,
  handleReady,
  handleStartGame,
  leaveRoom,
  disbandRoom,
  kickOut,
}) => {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(null);

  const toggleReady = () => {
    const newReadyState = !isReady;
    handleReady(newReadyState);
  };

  const user = JSON.parse(localStorage.getItem("user"));

  const copyRoomId = () => {
    navigator.clipboard.writeText(roomId).then(() => {});
    toast.success(t("waitingArea.roomIdCopied"));
  };

  const shareUrl = () => {
    const url = window.location.href;
    const title = "Hey! I am inviting you to join my room";
    const text = "Check out this link!";

    if (navigator.share) {
      navigator.share({
        title: title,
        text: text,
        url: url,
      })
      .then(() => {
    
      })
      .catch((error) => {
      
      });
    } else {
      console.error("Web Share API is not supported in this browser.");
      alert("Sharing not supported. Please copy the URL manually.");
    }
  };

  const toggleDropdown = (index) => {
    setShowDropdown(showDropdown === index ? null : index);
  };

  const handleKickOut = (playerId, playerName) => {
    kickOut(playerId);
    toast(`${playerName} ${t("waitingArea.kickedOutByHost")}`);
  };

  return (
    <div className="room-container">
      <div className="room-containers">
        <div className="room-content">
          <h2 className="game-title">{t("waitingArea.gameRoomTitle")}</h2>
          <div className="room-header">
            {roomId && (
              <div className="room-id">
                <h1 className="title">
                  {t("waitingArea.roomIdLabel")}: {roomId}
                </h1>
                <div>
                  <button className="copy-button" onClick={copyRoomId}>
                    {t("waitingArea.copyRoomId")}
                  </button>
                  <Link onClick={shareUrl}>
                    <IoMdShare />
                  </Link>
                </div>
              </div>
            )}
            <div className="room-details-content">
              <div className="room-title">
                {t("waitingArea.roomDetailsTitle")}
              </div>
              <div className="room-info">
                {t("waitingArea.hostName")}: {roomDetails.hostName}
              </div>
              <div className="room-info">{t("waitingArea.maxPlayers")}: {roomDetails.maxPlayers}</div>
              <div className="room-info">
                {t("waitingArea.inputTime")}: {roomDetails.inputTime} sec
              </div>
              <div className="room-info">
                {t("waitingArea.mcqTime")}: {roomDetails.mcqTime} sec
              </div>
              <div className="room-info">
                {t("waitingArea.language")}: {roomDetails.language}
              </div>
            </div>
          </div>
          <p className="players-list">{t("waitingArea.playersInRoom")}</p>
          <div className="players-grid">
            {players.map((player, index) => (
              <div
                key={index}
                className={`player-card ${
                  player.userId === roomDetails.hostId ? "host" : ""
                }`}
              >
                <div className="player-info">
                  <div className="player-info-cnt">
                    <span className="avatar">
                      <img
                        className="avatar-img"
                        alt={`Player ${index + 1}`}
                        src={User}
                      />
                    </span>
                    <div>
                      <div className="player-name">
                        {player.name}
                        {player.userId === roomDetails.hostId ? (
                          <span className="host-label">
                            {t("waitingArea.hostLabel")}
                          </span>
                        ) : (
                          <span className="host-label">
                            {t("waitingArea.playerLabel")}
                          </span>
                        )}
                      </div>
                      <span
                        className={`status-button ${
                          player.isReady ? "ready" : "not-ready"
                        }`}
                      >
                        {player.isReady
                          ? t("waitingArea.ready")
                          : t("waitingArea.notReady")}
                      </span>
                    </div>
                  </div>
                  {player.userId !== user.id && isHost && (
                    <div className="options-menu">
                      <button
                        className="menu-button"
                        onClick={() => toggleDropdown(index)}
                      >
                        &#x22EE;
                      </button>
                      {showDropdown === index && (
                        <div className="dropdown-menu">
                          <div
                            className="dropdown-item"
                            onClick={() =>
                              handleKickOut(player.userId, player.name)
                            }
                          >
                            {t("waitingArea.kick")}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <button className="action-button toggle-button" onClick={toggleReady}>
            {isReady ? t("waitingArea.notReady") : t("waitingArea.ready")}
          </button>
          {isHost ? (
            <>
              <button
                className="action-button start-button"
                onClick={handleStartGame}
              >
                {t("waitingArea.startGame")}
              </button>
              <button
                className="action-button leave-button"
                onClick={disbandRoom}
              >
                {t("waitingArea.disbandRoom")}
              </button>
            </>
          ) : (
            <button className="action-button leave-button" onClick={leaveRoom}>
              {t("waitingArea.leaveRoom")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default WaitingArea;
