import React, { useEffect, useState } from "react";
import "../../style/adminstyle/Feedback.scss";
import AdminSidebar from "../../components/admin/AdminSidebar";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Feedback = () => {
  const navigate = useNavigate();
  const [feedbacks, setFeedbacks] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const fetchFeedbacks = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/feedback/get-quiz-feedbacks`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      console.log("Feedbacks fetched:", response.data);
      setFeedbacks(response.data);
    } catch (error) {
      if (error.response && error.response.status >= 400) {
        window.location.href = '/';
      } else {
        console.error('Failed to fetch feedbacks', error);
      }
    }
  }

  useEffect(() => {
    fetchFeedbacks();
  }, []);

  const handleViewFeedback = (questionId) => {
    const questionFeedbacks = feedbacks.find(item => item._id === questionId);
    setSelectedQuestion(questionFeedbacks);
  };

  const handleBackToQuestions = () => {
    setSelectedQuestion(null);
  };

  const handleDeleteFeedback = async (questionId) => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/feedback/del-quiz-feedback/${questionId}`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      if (response.status === 200) {
        setFeedbacks(feedbacks.filter((item) => item._id !== questionId));
        if (selectedQuestion && selectedQuestion._id === questionId) {
          setSelectedQuestion(null);
        }
      } else {
        console.error('Failed to delete feedback: ', response.data);
      }
    } catch (error) {
      if (error.response && error.response.status >= 400) {
        window.location.href = '/';
      } else {
        console.error('Failed to delete feedback:', error);
      }
    }
  };
  

  return (
    <div className="admin-container">
      <AdminSidebar />
      <div className="feedback-container">
        <div className="header">
          <h2>Feedback</h2>
          <p className="text-muted">Review feedback from your users.</p>
        </div>
        
        {selectedQuestion ? (
          <div>
            <h3>Feedback for: {selectedQuestion.question.question}</h3>
            <button onClick={handleBackToQuestions} className="back-button">
              Back to Questions
            </button>
            <div className="feedback-table-wrapper">
              {selectedQuestion.feedbacks.length === 0 ? (
                <p>No feedback found.</p>
              ) : (
                <table className="feedback-table">
                  <thead>
                    <tr>
                      <th className="hidden-sm">Username</th>
                      <th className="hidden-sm">Email</th>
                      <th>Feedback</th>
                      <th className="hidden-sm">Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedQuestion.feedbacks.map((feedback) => (
                      <tr key={feedback._id}>
                        <td className="hidden-sm">{feedback.user?.name || 'N/A'}</td>
                        <td className="hidden-sm">{feedback.user?.email || 'N/A'}</td>
                        <td>
                          <div className="feedback-content">
                            <div>{feedback.message}</div>
                          </div>
                        </td>
                        <td className="hidden-sm">{new Date(feedback.createdAt).toLocaleDateString()}</td>
                        <td>
                          <button
                            className="delete-feedback-btn"
                            onClick={() => handleDeleteFeedback(selectedQuestion._id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        ) : (
          <div className="feedback-table-wrapper">
            {feedbacks.length === 0 ? (
              <p>No feedback found.</p>
            ) : (
              <table className="feedback-table">
                <thead>
                  <tr>
                    <th>Question</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {feedbacks.map((item) => (
                    <tr key={item._id}>
                      <td>
                        {item.question ? item.question.question : 'No question available'}
                      </td>
                      <td>
                        <div className="action-buttons">
                          <button
                            className="view-feedback-btn"
                            onClick={() => handleViewFeedback(item._id)}
                          >
                            View Feedbacks
                          </button>
                          <button
                            className="delete-feedback-btn"
                            onClick={() => handleDeleteFeedback(item._id)}
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Feedback;
