import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}/api/auth`; // Replace with your actual API URL

export const signIn = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/login`, { email, password });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const signInAsGuest = async () => {
  try {
    const response = await axios.post(`${API_URL}/guest-login`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const registerUser = async (userData) => {
    try {
      const response = await axios.post(`${API_URL}/register`, userData);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };
export const forgotPassword = async (email) => {
  try {
    const response = await axios.post(`${API_URL}/forgot-password`, {
      email
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const resetPassword = async (email, otp, newPassword) => {
  try {
    const response = await axios.post(`${API_URL}/reset-password`, {
      email, otp, newPassword
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};