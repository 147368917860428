import React, { useEffect, useState } from "react";
import "../../style/admin.scss";
import axios from 'axios';
import AdminSidebar from "../../components/admin/AdminSidebar";
import { useNavigate } from 'react-router-dom';

const QuestionArabic = () => {
  const navigate = useNavigate();
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [answerAlternatives, setAnswerAlternatives] = useState("");
  const [reference, setReference] = useState("");
  const [language, setLanguage] = useState("english");
  const [search, setSearch] = useState("");
  const [quizzes, setQuizzes] = useState([]);
  const [editingQuiz, setEditingQuiz] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalQuizzes, setTotalQuizzes] = useState(0);
  const [limit] = useState(10);
  const [selectedQuizzes, setSelectedQuizzes] = useState([]);

  const handleCreateQuiz = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/quizzes`, 
        { question, answer, answer_alternative: answerAlternatives.split(',').map(alt => alt.trim()), reference, language },
        {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }
      );
      fetchQuizzes();
      setQuestion("");
      setAnswer("");
      setReference("");
      setAnswerAlternatives("");
    } catch (error) {
      if (error.response && error.response.status >= 400) {
        navigate('/');
      } else {
        console.error('Failed to create quiz', error);
      }
    }
  };

  const handleDeleteQuiz = async (id) => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/quizzes/${id}`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      fetchQuizzes();
    } catch (error) {
      if (error.response && error.response.status >= 400) {
        navigate('/');
      } else {
        console.error('Failed to delete quiz', error);
      }
    }
  };

  const handleEditQuiz = (quiz) => {
    setEditingQuiz(quiz);
    setQuestion(quiz.question);
    setAnswer(quiz.answer);
    setReference(quiz.reference);
    setLanguage(quiz.language)
    setAnswerAlternatives(quiz.answer_alternative.join(', '));
  };

  const handleUpdateQuiz = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/quizzes/${editingQuiz._id}`, 
        { question, answer, answer_alternative: answerAlternatives.split(',').map(alt => alt.trim()), reference },
        {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }
      );
      fetchQuizzes();
      setEditingQuiz(null);
      setQuestion("");
      setAnswer("");
      setAnswerAlternatives("");
      setReference("");
    } catch (error) {
      if (error.response && error.response.status >= 400) {
        navigate('/');
      } else {
        console.error('Failed to update quiz', error);
      }
    }
  };

  const fetchQuizzes = async (page = currentPage, searchQuery = search) => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      console.log(`Fetching quizzes with query: ${searchQuery}, Page: ${page}`);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/quizzes/search`, {
        params: {
          page,
          limit,
          language:"arabic",
          q: searchQuery
        },
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      console.log("Quizzes fetched:", response.data);
      setQuizzes(response.data.quizzes);
      setTotalQuizzes(response.data.total);
    } catch (error) {
      if (error.response && error.response.status >= 400) {
        navigate('/');
      } else {
        console.error('Failed to fetch quizzes', error);
      }
    }
  };

  const handleSearch = async (e) => {
    setSearch(e.target.value);
    await fetchQuizzes(1, e.target.value);
  };

  useEffect(() => {
    fetchQuizzes();
  }, [currentPage]);

  const totalPages = Math.ceil(totalQuizzes / limit);

  const handleSelectQuiz = (quizId) => {
    setSelectedQuizzes(prev => 
      prev.includes(quizId) ? prev.filter(id => id !== quizId) : [...prev, quizId]
    );
  };

  const handleDeleteSelected = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      await Promise.all(selectedQuizzes.map(id => 
        axios.delete(`${process.env.REACT_APP_API_URL}/api/quizzes/${id}`, {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        })
      ));
      fetchQuizzes();
      setSelectedQuizzes([]);
    } catch (error) {
      if (error.response && error.response.status >= 400) {
        navigate('/');
      } else {
        console.error('Failed to delete selected quizzes', error);
      }
    }
  };

  return (
    <div className="admin-container">
      <AdminSidebar/>
    <div className="container">
    
      <main className="main">
        <div className="card-container">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">{editingQuiz ? "Edit Quiz" : "Create Arabic Quiz"}</h3>
              <p className="card-subtitle">{editingQuiz ? "Edit the quiz details." : "Create a new quiz for your platform."}</p>
            </div>
            <div className="card-body">
              <form className="form" onSubmit={editingQuiz ? handleUpdateQuiz : handleCreateQuiz}>
                <div className="admin-form-group">
                  <label className="form-label" htmlFor="question">Question</label>
                  <textarea
                    className="form-textarea"
                    id="question"
                    placeholder="Enter question"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    required
                  ></textarea>
                </div>
                <div className="admin-form-group">
                  <label className="form-label" htmlFor="answer">Answer</label>
                  <input
                    className="form-input"
                    id="answer"
                    placeholder="Enter answer"
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                    required
                  />
                </div>
                <div className="admin-form-group">
                  <label className="form-label" htmlFor="answerAlternatives">Answer Alternatives</label>
                  <input
                    className="form-input"
                    id="answerAlternatives"
                    placeholder="Enter answer alternatives (comma-separated)"
                    value={answerAlternatives}
                    onChange={(e) => setAnswerAlternatives(e.target.value)}
                  />
                </div>
                <div className="admin-form-group">
                  <label className="form-label" htmlFor="reference">Reference</label>
                  <input
                    className="form-input"
                    id="reference"
                    placeholder="Enter reference"
                    value={reference}
                    onChange={(e) => setReference(e.target.value)}
                  />
                </div>
                <div className="admin-form-group">
                  <label className="form-label" htmlFor="reference">Language</label>
                  <select name="language" className="form-input" value={language} onChange={(e) => setLanguage(e.target.value)}>
                    <option value="english">English</option>
                    <option value="arabic">Arebic</option>
                  </select>
                </div>
              
                <button className="form-button" type="submit">{editingQuiz ? "Update Quiz" : "Create Quiz"}</button>
              </form>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Manage Quizzes</h3>
              <p className="card-subtitle">Edit and delete existing quizzes.</p>
            </div>
            <div className="card-body">
              <div className="search-container">
                <input
                  className="search-input"
                  placeholder="Search quizzes..."
                  type="search"
                  value={search}
                  onChange={handleSearch}
                />
              </div>
              <div className="table-container">
                <table className="table">
                  <thead>
                    <tr className="table-row">
                      <th className="table-header">Select</th>
                      <th className="table-header">Question</th>
                      <th className="table-header">Answer</th>
                      <th className="table-header">Answer Alternatives</th>
                      <th className="table-header">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quizzes.map((quiz, index) => (
                      <tr key={index} className="table-row">
                        <td className="table-cell">
                          <input
                            type="checkbox"
                            checked={selectedQuizzes.includes(quiz._id)}
                            onChange={() => handleSelectQuiz(quiz._id)}
                          />
                        </td>
                        <td className="table-cell">{quiz.question}</td>
                        <td className="table-cell">{quiz.answer}</td>
                        <td className="table-cell">{quiz.answer_alternative.join(', ')}</td>
                        <td className="table-cell">
                          <button
                            className="edit-button"
                            onClick={() => handleEditQuiz(quiz)}
                          >
                            Edit
                          </button>
                          <button
                            className="delete-button"
                            onClick={() => handleDeleteQuiz(quiz._id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <button
                className="delete-selected-button"
                onClick={handleDeleteSelected}
                disabled={selectedQuizzes.length === 0}
              >
                Delete Selected ({selectedQuizzes.length})
              </button>
              <div className="pagination">
                <button
                  className="pagination-button"
                  onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <span className="pagination-info">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  className="pagination-button"
                  onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    </div>
  );
};

export default QuestionArabic;
