import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../style/adminstyle/gamesetting.scss";
import AdminSidebar from "../../components/admin/AdminSidebar";
import { useNavigate } from 'react-router-dom';

const GameSettings = () => {
  const navigate = useNavigate();
  // State to hold game settings
  const [settings, setSettings] = useState({
    minPlayers: 2,
    maxPlayers: 8,
    totalQuestions: 10,
  });

  // Fetch settings when component mounts
  useEffect(() => {
    const fetchSettings = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/about/`, {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        });
        setSettings(response.data);
      } catch (error) {
        if (error.response && error.response.status >= 400) {
          window.location.href = '/';
        } else {
          console.error("Failed to fetch settings:", error);
        }
      }
    };

    fetchSettings();
  }, [navigate]);

  // Update settings
  const handleUpdate = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/about/`, settings, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      alert("Settings updated successfully!");
    } catch (error) {
      if (error.response && error.response.status >= 400) {
        window.location.href = '/';
      } else {
        console.error("Failed to update settings:", error);
        alert("Failed to update settings.");
      }
    }
  };

  // Handle input changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [id]: value,
    }));
  };

  return (
    <div className="admin-container">
      <AdminSidebar />
      <div className="game-settings">
        <div className="game-settings__header">
          <h3 className="game-settings__title">Game Settings</h3>
          <p className="game-settings__description">
            Configure the parameters for your game or activity.
          </p>
        </div>
        <div className="game-settings__body">
          <div className="game-settings__input-group">
            <label className="game-settings__label" htmlFor="minPlayers">
              Minimum Players
            </label>
            <input
              className="game-settings__input"
              id="minPlayers"
              min="1"
              type="number"
              value={settings.minPlayers}
              onChange={handleChange}
            />
            <div className="game-settings__help-text">
              The minimum number of players required to start the game.
            </div>
          </div>
          <div className="game-settings__input-group">
            <label className="game-settings__label" htmlFor="maxPlayers">
              Maximum Players
            </label>
            <input
              className="game-settings__input"
              id="maxPlayers"
              min="2"
              type="number"
              value={settings.maxPlayers}
              onChange={handleChange}
            />
            <div className="game-settings__help-text">
              The maximum number of players that can participate in the game.
            </div>
          </div>
          <div className="game-settings__input-group">
            <label className="game-settings__label" htmlFor="totalQuestions">
              Number of Questions
            </label>
            <input
              className="game-settings__input"
              id="totalQuestions"
              min="2"
              type="number"
              value={settings.totalQuestions}
              onChange={handleChange}
            />
          </div>
        </div>
        
        <button
            className="form-button"
            type="button"
            onClick={handleUpdate}
          >
            Save Settings
          </button>
      </div>
    </div>
  );
};

export default GameSettings;
