import React, { useState } from "react";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { checkRoomJoinable } from "../services/roomServices";
import toast from "react-hot-toast";
import FullScreenLoader from "./FullScreenLoader"; // Import FullScreenLoader
import { useTranslation } from 'react-i18next';

const JoinRoom = () => {
  const { t } = useTranslation();
  const [roomId, setRoomIdState] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate(); 

  const token = localStorage.getItem('token');
  var user = localStorage.getItem('user');

  if (!token || !user) {
    console.error("No token found. User might not be authenticated.");
    navigate(`/login`);
    return;
  }
  user = JSON.parse(user);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    try {
      await checkRoomJoinable(roomId, token);
      navigate(`/play/${roomId}`, { state: { name } });
    } catch (error) {
      console.error("Error checking room joinable status:", error.message);
      toast.error(error.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="dialog">
      {loading && <FullScreenLoader />} {/* Show full screen loader when loading */}
      <div className="dialog-content">
        <div className="dialog-header">
          <h2>{t('joinRoom.header')}</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">{t('joinRoom.userNameLabel')}:</label>
            <TextField
              id="outlined-basic"
              label={t('joinRoom.userNamePlaceholder')}
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="roomId">{t('joinRoom.roomIdLabel')}:</label>
            <TextField
              id="outlined-basic"
              label={t('joinRoom.roomIdPlaceholder')}
              variant="outlined"
              value={roomId}
              onChange={(e) => setRoomIdState(e.target.value)}
              required
            />
          </div>
          <div className="dialog-footer">
            <button className="button" type="submit" disabled={loading}>
              {t('joinRoom.joinButton')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default JoinRoom;
