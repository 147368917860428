import React, { useState } from "react";
import "../../style/adminstyle/uploadquestion.scss";
import AdminSidebar from "../../components/admin/AdminSidebar";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const UploadQuestion = () => {
  const navigate = useNavigate();
  const [englishFile, setEnglishFile] = useState(null);
  const [arabicFile, setArabicFile] = useState(null);

  const handleFileChange = (e, setFile) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async (file, language) => {
    if (!file) {
      alert(`Please select a file to upload for ${language} questions.`);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/quizzes/import-quiz/${language}`, // Replace with your API URL
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${token}`
          },
        }
      );
      alert(`${language} questions uploaded successfully!`);
    } catch (error) {
      if (error.response && error.response.status >= 400) {
        window.location.href = '/';
      } else {
        alert(`Failed to upload ${language} questions: ${error.response?.data?.error || error.message}`);
      }
    }
  };

  return (
    <div className="admin-container">
      <AdminSidebar />
      <section className="upload-container">
        <div className="upload-content">
          <div className="upload-header">
            <h2 className="upload-title">Upload Question</h2>
            <p className="upload-description">
              Upload your English and Arabic Question in bulk
            </p>
          </div>
          <div className="upload-grid">
            <div className="upload-field">
              <label className="upload-label" htmlFor="english-file">
                English Question
              </label>
              <div className="upload-input-group">
                <input
                  className="upload-input"
                  id="english-file"
                  type="file"
                  onChange={(e) => handleFileChange(e, setEnglishFile)}
                />
                <button
                  className="upload-button"
                  type="button"
                  onClick={() => handleUpload(englishFile, "english")}
                >
                  Upload
                </button>
              </div>
            </div>
            <div className="upload-field">
              <label className="upload-label" htmlFor="arabic-file">
                Arabic Question
              </label>
              <div className="upload-input-group">
                <input
                  className="upload-input"
                  id="arabic-file"
                  type="file"
                  onChange={(e) => handleFileChange(e, setArabicFile)}
                />
                <button
                  className="upload-button"
                  type="button"
                  onClick={() => handleUpload(arabicFile, "arabic")}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UploadQuestion;
