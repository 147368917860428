import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_URL}/api/rooms`; // Replace with your actual API URL

export const checkRoomJoinable = async (roomId, token) => {
  try {
    const response = await axios.post(`${API_URL}/join`, { roomId }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }});
    return response;
  } catch (error) {
    console.error("Error checking room joinable status:", error.response.data);
    throw error.response.data;
  }
};
