import React from "react";
import Nav from "../components/nav";
import QuizQuestion from "../components/QuizMCQ";


function Question() {
  return <div>
    <QuizQuestion/>

  </div>;
}

export default Question;
